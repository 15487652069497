import React, { useMemo } from "react";
import { get } from "src/utils";
import useMarketoForm from "src/components/helpers/useMarketoForm";
import extractMarketoIdAndSerial from "src/components/helpers/extractMarketoIdAndSerial";
import getContentConnectorFields, { getContentConnectorAnchors } from "src/components/helpers/getContentConnectorFields";
import usePrependBaseURLIntoImages from "src/components/helpers/usePrependBaseURLIntoImages";
import getSchemaMarkup from "src/components/helpers/getSchemaMarkup";
import loadable from '@loadable/component'
import "src/scss/basicpages/_basicPages.scss";
import { StaticImage } from "gatsby-plugin-image";
import { SEO,LCPImage } from "src/components/commonComponents";
import { getPageDataJsonPath } from "src/utils";
import getOGMarkup from "src/components/helpers/getOGMarkup";
const getImageObject = require("src/../helpers/getImageObject")
const JumpLinks = loadable(() => import('src/components/commonComponents'),{resolveComponent: (components) => components.JumpLinks}); 
const ContentConnector = loadable(() => import('src/components/commonComponents'), {
  resolveComponent: (components) => components.ContentConnector
 });
 
const BasicPagesTemplate = ({ location, pageContext }) => {
  const locale = pageContext.locale || "en";
  const crumbs = [{ title: "Home", link: locale === "en" ? "/" : "/uk/" }, { title: pageContext.title }];
  let id = null;
  let serial = null;
  let gaLabel = null;
  const fieldMarketoFormEmbedCode = get(
    pageContext,
    "relationships.fieldMarketoFormNode.entity.fieldEmbedCode"
  );
  const fieldFormHeader = get(pageContext, "relationships.fieldMarketoFormNode.entity.fieldFormHeader");
  if (fieldMarketoFormEmbedCode) {
    id = extractMarketoIdAndSerial(fieldMarketoFormEmbedCode).id;
    serial = extractMarketoIdAndSerial(fieldMarketoFormEmbedCode).serial;
    gaLabel = extractMarketoIdAndSerial(fieldMarketoFormEmbedCode).gaLabel;

  }
  const { formTag } = useMarketoForm(serial, id, null, "", gaLabel);

  let contentConnectors = [];

  if (Array.isArray(pageContext?.relationships?.fieldContentSections)) {
    contentConnectors = getContentConnectorFields(
      pageContext.relationships?.fieldContentSections,
      "basicPage"
    );
  }

  const anchors = getContentConnectorAnchors(contentConnectors);
  usePrependBaseURLIntoImages(["basic-page-body"]);
  const schemaMarkup = getSchemaMarkup(pageContext?.fieldSchemaMarkup)
  const eventsTranslatedHero = pageContext?.relationships?.fieldHeroSection?.[0]
  const heroImage = getImageObject(eventsTranslatedHero?.relationships?.fieldImage, eventsTranslatedHero?.fieldImage);
  const OGMarkup = getOGMarkup(pageContext?.title, '', heroImage);

  let hreflangAlternatives = []

  pageContext.hreflangData?.forEach(hrefData => {
    hreflangAlternatives.push({
      href: hrefData.path,
      hreflang: hrefData.locale
    })
  });

  hreflangAlternatives?.find(trans => {
    if (trans.hreflang == "en") {
      hreflangAlternatives.push({
        href: trans.href,
        hreflang: "x-default"
      })
    } else if(hreflangAlternatives.length < 2) {
      if (trans.hreflang !== "en") {
        hreflangAlternatives.push({
          href: trans.href,
          hreflang: "x-default"
        })
      }
    }
  })

  const pageDataPath = getPageDataJsonPath(pageContext?.entityUrl?.path)

  const search = location.search;
  hreflangAlternatives = search.length > 0 ? [] : hreflangAlternatives
  return (
    <div className="container offset-navigation basic-page-template tw-basic-page-template">
        <SEO
        lang={locale}
        alternatives={hreflangAlternatives}
        title={pageContext.title} pageUrl={pageContext?.entityUrl?.path} meta={pageContext?.entityMetatags} 
        schemaMarkup={schemaMarkup}
        preloads= {[pageDataPath]}
        OGMarkup={OGMarkup}
        />
     { !!anchors?.length && (<div className="section">
      {

            <JumpLinks title={pageContext.fieldJumpLinksHeader} anchors={anchors}/>

        }

      </div>)}
      <div className="section">
        <LCPImage/>
        {/* <Breadcrumbs crumbs={crumbs} /> */}
        {eventsTranslatedHero && <div className="event-overview-page tw-font-Roboto tw-mb-[76px]">
          <div className="container">
            <div className="header-wrapper tw-mt-[32px] md:tw-mt-[52px] tw-relative">
              <div className="banner-image tw-absolute tw-top-[-200px]">
                {pageContext?.showBackgroundBanner && <StaticImage
                    src={"../images/Interactive-Demo-top-bg-image.png"}
                    alt={'Banner Image'}
                    decoding = "async"
                />}
              </div>
              <div>
                <div className="eyebrow tw-text-[12px] tw-mb-[8px] tw-text-accruent_base_color">{eventsTranslatedHero?.fieldPreHeading}</div>
                <h1 className="tw-text-xxxlFont md:tw-text-headingFont tw-mb-[16px] tw-ml-0 tw-transform-none tw-font-Poppins">{eventsTranslatedHero?.title}</h1>
                <p className="tw-text-[22px] tw-leading-[26px] tw-w-full md:tw-text-[24px] md:tw-leading-[32px] md:tw-w-[70%] tw-font-medium tw-mb-[15px] tw-text-accruent_black">{eventsTranslatedHero?.fieldHeroDescription}</p>
              </div>
            </div>
          </div>
        </div>}

        {pageContext.body && (
          <div className="content">
            <div className="basic-page-body tw-basic-page-body dangerously-html-handle" dangerouslySetInnerHTML={{ __html: pageContext.body.value }} />
          </div>
        )}

        {contentConnectors.map((data, index) => (
          <ContentConnector
            key={index}
            data={data}
            locale={locale}
          />
        ))}
        {formTag && (
          <div className="columns">
            <div className="column">
              {fieldFormHeader && (
                <h3 className="title title-4">
                  {fieldFormHeader}
                </h3>
              )}
              {formTag}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default BasicPagesTemplate;